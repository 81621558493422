export default {
    common: {
        title: "Разработка научно-лингвистических основ и IT-ресурсов по расширению функций и повышению культуры казахского языка",
        select: "Выберите аудио файл или нажмите на запись",
        result: "Результат",
        sponsor: "Проект разработан на основе гранта Министерства образивании и науки Республики Казахстан (ИРН: BR11765535)",
        text: "Текст",
        translateText: "Текст перевода",
    },
    qas: {
        title: "Вопросно-ответная система",
        description: "Ответ на заданный вопрос формируется на основе онтологии потроенной по базе знаний выбранного раздела",
        answer: "Ответ",
        other: "Также",
    },
}