export default {
    common: {
        title: "Қазақ тілінің функцияларын кеңейту және мәдениетін арттыру үшін ғылыми-лингвистикалық негіздер мен IT-ресурстарды әзірлеу",
        select:"Аудио файлды таңдаңыз немесе дауыс жазыңыз",
        result:"Нәтижесі",
        sponsor: "Жоба Қазақстан Республикасы Білім және ғылым министрлігі гранты негізінде дайындалған (ЖТН: BR11765535)",
        text: "Мәтін",
        translateText: "Аударма мәтіні",
    },
    qas: {
        title: "Сұрақ-жауап жүйесі",
        description: "Қойылған сұраққа жауап Грамматика бойынша жаңа білім жүйесінен құралған онтологиялық модель негізінде орындалады.",
        answer: "Жауабы",
        other: "Бұдан өзге",
    },
}