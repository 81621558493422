<template>
  <div>
    <Textarea rows="15" cols="55" v-model="inputWords" style="width: 100%" />
    <div class="row texts" style="margin-top: 10px; align-items: center">
      <div style="display: flex; flex-direction: column; margin-right: 10%">
        <Knob
          v-model="inputWords.length"
          disabled
          :size="60"
          :max="2000"
          :min="0"
        />
        <span>Символдар саны</span>
      </div>
      <div style="display: flex; flex-direction: column">
        <Knob
          v-model="synomized_counter"
          disabled
          :size="60"
          :max="400"
          :min="0"
        />
        <span>Ауыстырылған сөздер саны</span>
      </div>

      <!-- <div>Символдар саны = {{ inputWords.length }}</div>
      <div>Ауыстырылған сөздер саны = {{ synomized_counter }}</div> -->
      <Button
        label="Өңдеу"
        @click="send_to_synomize"
      />
    </div>
    <div
      v-show="loading == false"
      class="card"
      style="
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        overflow: hidden;
        height: 130px;
        max-height: 130px;
        padding-bottom: 40px;
      "
    >
      <div
        style="
          width: 100%;
          overflow-y: auto;
          padding-right: 10px;
          max-height: 100px;
          height: 100px;
        "
      >
        <div class="word-container">
          <span v-for="(word, index) in synomized_words" :key="index">
            <span
              v-if="isHtml(word)"
              v-html="word"
              @click="handleLineClick"
            ></span>
            <span v-else>{{ word }}</span>
          </span>
        </div>

        <OverlayPanel
          ref="op"
          style="border: none; padding: 0"
          v-if="synomized_counter != 0"
        >
          <div class="border-inner">
            <Listbox
              v-model="selectedSyn"
              :options="optionSynonyms"
              optionLabel="synonym"
              class="w-full"
              style="
                border: none;
                margin: 0;
                max-height: 80px;
                overflow-y: auto;
              "
              @change="onSynonymTap"
            />
          </div>
        </OverlayPanel>
      </div>
    </div>
    <Skeleton
      v-show="loading"
      style="
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        overflow: hidden;
        height: 130px;
        max-height: 130px;
        padding-bottom: 40px;
      "
    ></Skeleton>
  </div>
</template>
<script>
import axios from "axios";
import { AHMET_API, getHeader } from "../../config";
import { AhmetService } from "../../service/AhmetService";

export default {
  data() {
    return {
      overlayService: null,
      inputWords: "",
      synomized_counter: 0,
      synomized_words: [],
      optionSynonyms: [{ synonym: "синоним жоқ", words: "" }],
      selectedSyn: null,
      overlayTarget: null,
      clickedElHref: "",
      second_part: "",
      loading: false,
    };
  },

  methods: {
    forceRerender() {
      this.synomized_counter = 0;
    },
    async send_to_synomize() {
      this.loading = true;
      this.forceRerender();
      await axios
        .post(
          `${AHMET_API}/search/word/`,
          {
            value: this.inputWords,
          },
          { headers: getHeader() }
        )
        .then((response) => {
          // console.log(response);
          this.synomized_words = response.data[0];
          this.synomized_counter = response.data[1];
        });
      this.loading = false;
    },
    async handleLineClick(e) {
      this.clickedElHref = e.target.getAttribute("href");
      const clickedElClass = e.target.getAttribute("class");
      const clickkedRef = e.target.getAttribute("second_part");
      const family = e.target.getAttribute("family");
      const target = e.target;

      if (target.tagName === "SPAN") {
        this.overlayTarget = target;
      }
      if (clickedElClass === "temp_testing_div2") {
        // const dataValue = e.target.getAttribute("id");
        // console.log("dataValue: ", dataValue);
        // console.log("overlayTarget:", this.overlayTarget);
        this.handleClick(e);
        // console.log("temp_testing_div2 clicked!", this.clickedElHref);
        // console.log("clickkedRef:", clickkedRef);
        this.second_part = clickkedRef;
        // console.log("family:", family);
        this.optionSynonyms = [
          {
            synonym: this.clickedElHref,
            words: this.clickedElHref,
          },
        ];
        await axios
          .post(
            `${AHMET_API}/search/synonyms/only`,
            {
              value: this.clickedElHref,
              second_part: clickkedRef,
              secondary: this.overlayTarget.innerText,
              family: family,
            },
            { headers: getHeader() }
          )
          .then((response) => {
            this.optionSynonyms = response.data;
          });
        // this.optionSynonyms = this.clearArray(this.optionSynonyms);
        // console.log("this.optionSynonyms:", this.optionSynonyms);
      } else {
        // console.log("another element was clicked");
      }
    },
    handleClick(event) {
      // console.log("Span clicked!");
      this.toggle(event);
    },
    toggle(event) {
      // console.log("toggle: ", event.target);
      this.$refs.op.toggle(event);
    },
    async onSynonymTap() {
      const responsen = await AhmetService.research(
        this.optionSynonyms[0].synonym,
        this.selectedSyn.synonym
      );
      // console.log(this.optionSynonyms);
      // console.log("selectedSyn: ", this.selectedSyn);
      // console.log("selectedSyn: ", this.selectedSyn.synonym);
      if (this.optionSynonyms[0].synonym.includes(this.selectedSyn.synonym)) {
        this.overlayTarget.innerText = this.optionSynonyms[0].synonym;
      } else {
        this.overlayTarget.innerText = responsen;
      }
      this.closeOverlayPanel();
    },
    closeOverlayPanel() {
      this.$refs.op.hide();
    },
  },
  computed: {
    isHtml() {
      return function (word) {
        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(word, "text/html");
        return parsedHtml.body.childNodes.length !== 0;
      };
    },
  },
};
// :appendTo="overlayTarget"
</script>
<style setup>
.card-container {
  height: 300px; /* Set the desired height for the container */
  /* Enable vertical scrolling */
}
.row {
  display: flex;
  align-items: center;
}
.row div {
  margin-right: 10px;
}
.texts {
  font-size: 10px;
  margin-top: 10px;
}
.rowMain {
  display: flex;
  align-items: center;
}
.temp-mobile {
  display: none;
}
.dropDown {
  border-radius: 100px;
}
.bounder {
  /* //align-items: center; */
  height: 700px;
}
</style>
