export default {
    common: {
        title: "Development of scientific and linguistic foundations and IT resources to expand the functions and improve the culture of the Kazakh language",
        select:"Select audio file or rec audio",
        result:"Result",
        sponsor: "The project was developed on the basis of a grant from the Ministry of Education and Science of the Republic of Kazakhstan (BR11765535)",
        text: "Text",
        translateText: "Translation text",
    },
    qas:{
        title: "Question-answering system",
        description: "The answer to the question asked is formed on the basis of an ontology built on the knowledge base of the selected section",
        answer: "Answer",
        other: "Also",
    },
  
}